import moment from "moment";
import {
    RECEIVE_LOGIN,
    EDUCATION_REPORT,
    FACILITY_LIST,
    AUDIT_REPORT,
    EQUIPMENT_MAINTENANCE_REPORT,
    SEND_MAIL,
    EDUCATION_CHART,
    CLEANING_AUDIT_CHART,
    LAUNDRY_AUDIT_CHART,
    RISK_ASSESSMENT_CHART,
    STAFF_CHART,
    KPI_INSPECTION_REPORT,
    KEY_SWIPE_REPORT,
    IMMUNISATION_REPORT,
    POLICE_CHECK,
    RECEIVE_FORGOT,
    UPDATE_PASSWORD,
    CLEANING_MONTH_AUDIT_REPORT,
    LAUNDRY_MONTH_AUDIT_REPORT,
    PERIODICALS,
    CERTIFICATES_DATA,
    SDSCHEMICAL_DATA,
    REGISTERCHEMICAL_DATA,
    SUPPLIERCERTIFICATE_DATA,
    WORKCOVER_CERTIFICATE,
    PERIODICALS_FACILITY_WISE,
    GET_INFECTION_CONTROL,
    GET_TSA_CERTIFICATE,
    VACCINATION_CERTIFICATES,
    VACCINATION_STATEMENT,
    COVID19_TRAINIG_CERTIFICATE,
    SERVEY_CLEANING_LAUNDRY,
    GET_VACCINATION_TYPE,
    VACCINATION_STATMENT,
    ROLL_OUT_DATA,
    EQUIPMENT_MAINTENANCE_REGISTER,
    GET_SUPER_USER_FACILITY_LIST,
    GET_GROUP_LIST,
    GET_FACILITY_LIST,
    USER_LOG,
    GET_MEETING_MINUTES,
    GET_AIR_PURIFIRE,
    SET_MANAGER_ORGANIZATIONS_DATA,
    SET_FACILITY_BY_MANAGER,
    SET_WEB_TOUR,
    SET_FACILITY_WISE_PERIODICAL_DATA,
    SET_PERIODICAL_RECORDS,
    FILE_LOADING_STATE,
    SET_STATE_DATA,
    SET_CERTIFICATE_DATA,
    TSA_CERTIFICATE,
    SET_ADDITIONAL_OUTBREAK_DETAILS,
    SET_ALL_SITE_DETAILS,
    SET_SITE_SUMMARY_REPORT,
    SET_MAINTENANCE_REPORT,
    SET_UNABLE_TO_CLEAN_REPORT,
    GET_FACILITY_ELEMENT_AUDIT_SCORE,
    GET_GROUP_ELEMENT_AUDIT_SCORE,
    PERIODICAL_RECORDS_CERTIFICATE,
    UPLOAD_PERIODICAL_SELECTED_YEAR,
    PERIODICAL_RECORDS_SELECTED_FACILITY
} from "../actions/actions";

function commonReducer(
    state = {
        login_user_data: [],
        education_report_data: [],
        facility_list: [],
        audit_report_data: [],
        equipment_maintenance_report_data: [],
        send_email: [],
        education_chart: [],
        cleaning_audit_chart: [],
        laundry_audit_chart: [],
        risk_assessment_chart: [],
        staff_chart: [],
        kpi_inspection_data: [],
        key_swipe_data: [],
        immunisation_data: [],
        police_check_data: [],
        forgot_user_data: [],
        update_password: [],
        cleaning_audit_report_month_data: [],
        laundry_audit_report_month_data: [],
        periodicals: [],
        certificates_data: [],
        sds_chemical_data: [],
        register_chemical_data: [],
        supplier_certificate_data: [],
        workcover_certificate_data: [],
        periodicals_facility_wise: [],
        get_infection_control: [],
        get_tsa_certificate_list: [],
        vaccination_certificates: [],
        vaccination_statement: [],
        covid19_training_certificate: [],
        servey_cleaning_servey: [],
        get_vaccination_type: [],
        vaccination_statment: [],
        roll_out_data: [],
        equipment_register_data: [],
        super_user_facility_status_data: [],
        group_status_data: [],
        facility_status_data: [],
        user_log: [],
        meeting_minutes_data: [],
        manager_organization_data: [],
        facility_by_manager: [],
        set_web_tour: false,
        periodical_report_data: [],
        fileLoadingState: "",
        state_data: [],
        certificate_data: [],
        tsa_certificate: [],
        aoc_details: [],
        all_site_details: [],
        daily_site_summary_report: [],
        maintenance_report: [],
        facilityElementAuditScore: [],
        groupElementAuditScore: [],
        periodical_records_data: [],
        upload_periodical_selected_year: moment(),
    },
    action
) {
    switch (action.type) {
        case RECEIVE_LOGIN:
            return Object.assign({}, state, {
                login_user_data: action.login_user_data,
            });

        case EDUCATION_REPORT:
            return Object.assign({}, state, {
                education_report_data: action.education_report_data,
            });

        case FACILITY_LIST:
            return Object.assign({}, state, {
                facility_list: action.facility_list,
            });

        case AUDIT_REPORT:
            return Object.assign({}, state, {
                audit_report_data: action.audit_report_data,
            });

        case EQUIPMENT_MAINTENANCE_REGISTER:
            return Object.assign({}, state, {
                equipment_register_data: action.equipment_register_data,
            });

        case EQUIPMENT_MAINTENANCE_REPORT:
            return Object.assign({}, state, {
                equipment_maintenance_report_data:
                    action.equipment_maintenance_report_data,
            });

        case SEND_MAIL:
            return Object.assign({}, state, {
                send_email: action.send_email,
            });

        case EDUCATION_CHART:
            return Object.assign({}, state, {
                education_chart: action.education_chart,
            });

        case CLEANING_AUDIT_CHART:
            return Object.assign({}, state, {
                cleaning_audit_chart: action.cleaning_audit_chart,
            });

        case LAUNDRY_AUDIT_CHART:
            return Object.assign({}, state, {
                laundry_audit_chart: action.laundry_audit_chart,
            });

        case RISK_ASSESSMENT_CHART:
            return Object.assign({}, state, {
                risk_assessment_chart: action.risk_assessment_chart,
            });

        case STAFF_CHART:
            return Object.assign({}, state, {
                staff_chart: action.staff_chart,
            });

        case KPI_INSPECTION_REPORT:
            return Object.assign({}, state, {
                kpi_inspection_data: action.kpi_inspection_data,
            });

        case KEY_SWIPE_REPORT:
            return Object.assign({}, state, {
                key_swipe_data: action.key_swipe_data,
            });

        case IMMUNISATION_REPORT:
            return Object.assign({}, state, {
                immunisation_data: action.immunisation_data,
            });

        case POLICE_CHECK:
            return Object.assign({}, state, {
                police_check_data: action.police_check_data,
            });

        case RECEIVE_FORGOT:
            return Object.assign({}, state, {
                forgot_user_data: action.forgot_user_data,
            });

        case UPDATE_PASSWORD:
            return Object.assign({}, state, {
                update_password: action.update_password,
            });

        case PERIODICALS:
            return Object.assign({}, state, {
                periodicals: action.periodicals,
            });

        case CLEANING_MONTH_AUDIT_REPORT:
            return Object.assign({}, state, {
                cleaning_audit_report_month_data:
                    action.cleaning_audit_report_month_data,
            });

        case LAUNDRY_MONTH_AUDIT_REPORT:
            return Object.assign({}, state, {
                laundry_audit_report_month_data:
                    action.laundry_audit_report_month_data,
            });

        case CERTIFICATES_DATA:
            return Object.assign({}, state, {
                certificates_data: action.certificates_data,
            });

        case SDSCHEMICAL_DATA:
            return Object.assign({}, state, {
                sds_chemical_data: action.sds_chemical_data,
            });

        case REGISTERCHEMICAL_DATA:
            return Object.assign({}, state, {
                register_chemical_data: action.register_chemical_data,
            });

        case SUPPLIERCERTIFICATE_DATA:
            return Object.assign({}, state, {
                supplier_certificate_data: action.supplier_certificate_data,
            });

        case WORKCOVER_CERTIFICATE:
            return Object.assign({}, state, {
                workcover_certificate_data: action.workcover_certificate_data,
            });

        case PERIODICALS_FACILITY_WISE:
            return Object.assign({}, state, {
                periodicals_facility_wise: action.periodicals_facility_wise,
            });

        case GET_INFECTION_CONTROL:
            return Object.assign({}, state, {
                get_infection_control: action.get_infection_control,
            });

        case GET_TSA_CERTIFICATE:
            return Object.assign({}, state, {
                get_tsa_certificate_list: action.get_tsa_certificate_list,
            });

        case VACCINATION_CERTIFICATES:
            return Object.assign({}, state, {
                vaccination_certificates: action.vaccination_certificates,
            });

        case VACCINATION_STATEMENT:
            return Object.assign({}, state, {
                vaccination_statement: action.vaccination_statement,
            });

        case COVID19_TRAINIG_CERTIFICATE:
            return Object.assign({}, state, {
                covid19_training_certificate:
                    action.covid19_training_certificate,
            });

        case SERVEY_CLEANING_LAUNDRY:
            return Object.assign({}, state, {
                servey_cleaning_servey: action.servey_cleaning_servey,
            });

        case GET_VACCINATION_TYPE:
            return Object.assign({}, state, {
                get_vaccination_type: action.get_vaccination_type,
            });

        case VACCINATION_STATMENT:
            return Object.assign({}, state, {
                vaccination_statment: action.vaccination_statment,
            });

        case ROLL_OUT_DATA:
            return Object.assign({}, state, {
                roll_out_data: action.roll_out_data,
            });

        case GET_GROUP_LIST:
            return Object.assign({}, state, {
                group_status_data: action.group_status_data,
            });

        case GET_FACILITY_LIST:
            return Object.assign({}, state, {
                facility_status_data: action.facility_status_data,
            });

        case GET_SUPER_USER_FACILITY_LIST:
            return Object.assign({}, state, {
                super_user_facility_status_data:
                    action.super_user_facility_status_data,
            });

        case USER_LOG:
            return Object.assign({}, state, {
                user_log: action.user_log,
            });

        case GET_MEETING_MINUTES:
            return Object.assign({}, state, {
                meeting_minutes_data: action.meeting_minutes_data,
            });
        case GET_AIR_PURIFIRE:
            return Object.assign({}, state, {
                air_purifire_data: action.air_purifire_data,
            });

        case SET_MANAGER_ORGANIZATIONS_DATA:
            return Object.assign({}, state, {
                manager_organization_data: action.manager_organization_data,
            });

        case SET_FACILITY_BY_MANAGER:
            return Object.assign({}, state, {
                facility_by_manager: action.facility_by_manager,
            });

        case SET_WEB_TOUR:
            return Object.assign({}, state, {
                set_web_tour: action.set_web_tour,
            });

        case SET_FACILITY_WISE_PERIODICAL_DATA:
            return Object.assign({}, state, {
                periodical_report_data: action.periodical_report_data,
            });

        case SET_PERIODICAL_RECORDS:
            return Object.assign({}, state, {
                periodical_records: action.periodical_records,
            });

        case FILE_LOADING_STATE:
            return Object.assign({}, state, {
                fileLoadingState: action.fileLoading,
            });

        case SET_STATE_DATA:
            return Object.assign({}, state, {
                state_data: action.state_data,
            });

        case SET_CERTIFICATE_DATA:
            return Object.assign({}, state, {
                certificate_data: action.certificate_data,
            });

        case TSA_CERTIFICATE:
            return Object.assign({}, state, {
                tsa_certificate: action.tsa_certificate,
            });

        case SET_ADDITIONAL_OUTBREAK_DETAILS:
            return Object.assign({}, state, {
                aoc_details: action.aoc_details,
            });

        case SET_ALL_SITE_DETAILS:
            return Object.assign({}, state, {
                all_site_details: action.all_site_details,
            });

        case SET_SITE_SUMMARY_REPORT:
            return Object.assign({}, state, {
                daily_site_summary_report: action.daily_site_summary_report,
            });

        case SET_MAINTENANCE_REPORT:
            return Object.assign({}, state, {
                maintenance_report: action.maintenance_report,
            });

        case SET_UNABLE_TO_CLEAN_REPORT:
            return Object.assign({}, state, {
                unableToClean_report: action.unableToClean_report,
            });
        
        case GET_FACILITY_ELEMENT_AUDIT_SCORE:
        return Object.assign({}, state, {
            facilityElementAuditScore: action.facilityElementAuditScore,
        });
        
        case GET_GROUP_ELEMENT_AUDIT_SCORE:
        return Object.assign({}, state, {
            groupElementAuditScore: action.groupElementAuditScore,
        });
        case UPLOAD_PERIODICAL_SELECTED_YEAR:
            return Object.assign({}, state, {
                upload_periodical_selected_year: action.upload_periodical_selected_year,
            });
        case PERIODICAL_RECORDS_SELECTED_FACILITY:
            return Object.assign({}, state, {
                periodical_records_selected_facility: action.periodical_records_selected_facility,
            });
        case PERIODICAL_RECORDS_CERTIFICATE:
            return Object.assign({}, state, {
                periodical_records_data: action.periodical_records_data,
            });

        default:
            return state;
    }
}
export default commonReducer;
