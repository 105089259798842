import React, { Component } from "react";
import { Route } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../dashboard")),
  },

  {
    path: "education",
    component: asyncComponent(() => import("../Reports/Education")),
  },
  {
    path: "equipment_maintenance",
    component: asyncComponent(() => import("../Reports/EquipmentMaintenance")),
  },
  // {
  //   path: 'survey',
  //   component: asyncComponent(() => import('../Reports/Survey')),
  // },
  {
    path: "cleaning_audit",
    component: asyncComponent(() => import("../Reports/CleaningAudit")),
  },
  {
    path: "landry_audit",
    component: asyncComponent(() => import("../Reports/LandryAudit")),
  },
  {
    path: "maintenance_report",
    component: asyncComponent(() => import("../Reports/MaintenanceAudit")),
  },
  {
    path: "housekeeping_report",
    component: asyncComponent(() => import("../Reports/HouseKeeping")),
  },
  {
    path: "equipment_register",
    component: asyncComponent(() => import("../Reports/EquipmentRegister")),
  },
  {
    path: "periodical",
    component: asyncComponent(() => import("../Reports/Periodical")),
  },
  {
    path: "cleaning_excellence",
    component: asyncComponent(() => import("../Reports/CleaningExcellence")),
  },
  {
    path: "daily_site_summary",
    component: asyncComponent(() => import("../Reports/CleaningExcellence/dailySiteSummaryReport")),
  },
  {
    path: "unable_to_clean",
    component: asyncComponent(() => import("../Reports/CleaningExcellence/unAbleToCleanReport")),
  },
  {
    path: "maintenance",
    component: asyncComponent(() => import("../Reports/CleaningExcellence/maintenanceReport")),
  },
  {
    path: "KPI_inspection",
    component: asyncComponent(() => import("../Reports/KpiInspection")),
  },
  {
    path: "key_swipe",
    component: asyncComponent(() => import("../Reports/KeySwipe")),
  },
  {
    path: "police_check",
    component: asyncComponent(() => import("../Reports/PoliceCheck")),
  },
  {
    path: "cleaning_survey",
    component: asyncComponent(() => import("../Reports/CleaningSurvey")),
  },
  {
    path: "laundry_survey",
    component: asyncComponent(() => import("../Reports/LandrySurvey")),
  },
  {
    path: "widget",
    component: asyncComponent(() => import("../Widgets")),
  },
  {
    path: "my_profile",
    component: asyncComponent(() => import("../Profile")),
  },
  // {
  //   path: 'periodicals',
  //   component: asyncComponent(() => import('../Periodicals/')),
  // },
  {
    path: "public_liability",
    component: asyncComponent(() => import("../Certificates/PublicLaibility")),
  },
  {
    path: "workcover",
    component: asyncComponent(() => import("../Certificates/WorkCover")),
  },
  {
    path: "ISO_certificates",
    component: asyncComponent(() => import("../Certificates/ISO")),
  },
  {
    path: "CM3_certificate",
    component: asyncComponent(() => import("../Certificates/CM3")),
  },
  {
    path: "executive_KPI_report",
    component: asyncComponent(() =>
      import("../Reports/KpiInspection/ExecutiveKPI")
    ),
  },
  {
    path: "facility_KPI_reports",
    component: asyncComponent(() =>
      import("../Reports/KpiInspection/FacilityKPI")
    ),
  },
  {
    path: "SDS",
    component: asyncComponent(() => import("../Chemical/SDS")),
  },
  {
    path: "chemical_register",
    component: asyncComponent(() => import("../Chemical/ChemicalRegisters")),
  },
  {
    path: "supplier_certificate",
    component: asyncComponent(() => import("../Chemical/Certificate")),
  },
  {
    path: "transition_program",
    component: asyncComponent(() => import("../Reports/TranslationPlan/")),
  },
  {
    path: "infection_control_certificate",
    component: asyncComponent(() =>
      import("../Covid-19/Infection-Control-Certificate/")
    ),
  },
  {
    path: "tsa_aged_care_fundamentals",
    component: asyncComponent(() =>
      import("../Covid-19/Tsa-Agedcare-Fundamentals/")
    ),
  },
  {
    path: "vaccination_certificates",
    component: asyncComponent(() =>
      import("../Immunisation/Vaccination-Certificates/")
    ),
  },
  {
    path: "vaccination_statement",
    component: asyncComponent(() =>
      import("../Immunisation/Vaccination-Statement/")
    ),
  },
  {
    path: "covid_training_certificate",
    component: asyncComponent(() =>
      import("../Covid-19/Covid-Training-Certificate/")
    ),
  },
  {
    path: "covid_vaccine_statement",
    component: asyncComponent(() =>
      import("../Immunisation/Covid-Vaccine-Statement/")
    ),
  },

  {
    path: "meeting-minutes",
    component: asyncComponent(() =>
      import("../meetingMinutes/")
    ),
  },
  {
    path: "air-purifier",
    component: asyncComponent(() =>
      import("../AirPurifire/")
    ),
  },
  {
    path: "periodical-records",
    component: asyncComponent(() =>
      import("../PeriodicalRecords/index")
    ),
  },
  {
    path: "tsa-contract",
    component: asyncComponent(() =>
      import("../contract/index")
    ),
  },
  {
    path: "tsa_contact",
    component: asyncComponent(() =>
      import("../TSA/tsaContact")
    ),
  },
  {
    path: "additional-outbreak-cleaning",
    component: asyncComponent(() =>
      import("../AOC/index")
    ),
  },
  {
    path: "roll_out_plan",
    component: asyncComponent(() => import("../Reports/RollOutData/")),
  },
];

class AppRouter extends Component {
  render() {
    // console.log("====islogin check==========",localStorage.getItem('email'));
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
